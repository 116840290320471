import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import * as Scroll from 'react-scroll'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import RightChevron from '../components/SVG/RightChevron'
import ChevronThree from '../components/SVG/ChevronThree'

const IndexPage = ({ data, location }) => {
  const page = data.prismicHome.data
  const article = page.featured_project.document

  let ScrollLink = Scroll.Link
  let Element = Scroll.Element

  return (
    <Layout location={location}>
      <SEO
        title={page.meta_title && page.meta_title}
        location={location}
        description={page.meta_description && page.meta_description}
      />
      <div className="wrapper relative">
        <div className="lg:bg-white md:w-1/2 lg:pr-20 pb-16 lg:pb-20 pt-10 lg:absolute lg:top-0 lg:left-0 z-10">
          {page.subheading && (
            <h2 className="text-xs font-black tracking-wider lg:tracking-normal uppercase lg:text-base lg:normal-case lg:font-semibold mb-3 lg:mb-4">
              {page.subheading}
            </h2>
          )}
          {page.heading && (
            <div
              className="heading-2xl rte rte--link"
              dangerouslySetInnerHTML={{ __html: page.heading.html }}
            />
          )}

          <ScrollLink
            to="content"
            smooth={true}
            className="hidden button button--blue button--down absolute lg:flex items-center justify-center w-20 h-18 left-0 top-100"
          >
            <ChevronThree />
          </ScrollLink>
        </div>
        <div className="-mx-5 sm:mx-0 lg:pt-40">
          <div className="lg:flex justify-end relative">
            {article && (
              <>
                <div className="w-1/2 lg:w-auto py-6 px-8 bg-blue-30">
                  <h4 className="font-semibold">Featured Project</h4>
                </div>

                <Link
                  className="button button--blue block lg:hidden w-full lg:w-auto py-6 px-8 font-semibold"
                  to={'/case-studies/' + page.featured_project.uid}
                >
                  {article.data.heading} <RightChevron className="ml-8" />
                </Link>
                <div className="hidden lg:block text-white bg-blue relative z-10">
                  <Link
                    className="button button--blue button--break-out z-10"
                    to={'/case-studies/' + page.featured_project.uid}
                  />
                  <div className="button__inner z-20 relative h-full pl-8 pointer-events-none">
                    {article.data.heading} <RightChevron className="ml-8" />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="lg:break-out-right">
            {article.data.hero.url && (
              <Img
                fluid={article.data.hero.localFile.childImageSharp.fluid}
                alt={article.data.hero.alt}
              />
            )}
          </div>
        </div>
      </div>
      <Element name="content" className="lg:bg-blue">
        <div className="wrapper bg-white bg-after-white bg-after-white--left lg:-mt-10">
          <div className="lg:flex lg:-ml-12 py-24">
            <div className="lg:w-1/3 lg:pl-12">
              <div className="flex flex-col justify-between h-full">
                {page.title && (
                  <h3 className="heading-lg mb-12 lg:mb-0">{page.title}</h3>
                )}
                {page.link_text && (
                  <div className="hidden lg:block xl:w-3/4">
                    <Link
                      className="flex button button--white py-6 mb-0 justify-between items-center font-semibold border-blue border"
                      to="/about"
                    >
                      {page.link_text}
                      <div className="ml-8">
                        <RightChevron />
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="hidden lg:block w-1/12 pl-12" />
            {page.lead.html && (
              <div
                className="font-semibold rte mb-24 lg:mb-0 lg:w-1/2 lg:pl-12 leading-relaxed"
                dangerouslySetInnerHTML={{ __html: page.lead.html }}
              />
            )}

            {page.link_text && (
              <div className="lg:hidden">
                <Link
                  className="flex button button--white mb-0 justify-between items-center border-blue border"
                  to="/about"
                >
                  {page.link_text}
                  <div className="ml-8">
                    <RightChevron />
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </Element>
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageHomeQuery = graphql`
  query {
    prismicHome {
      data {
        subheading
        heading {
          html
        }
        title
        lead {
          html
          text
        }
        featured_project {
          uid
          document {
            ... on PrismicCaseStudiesArticle {
              data {
                hero {
                  url
                  alt
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2019, maxHeight: 914) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                lead {
                  html
                  text
                }
                heading
              }
            }
          }
          id
        }
        link_text
        meta_title
        meta_description
      }
    }
  }
`

export default IndexPage
